<template>
  <div :class="aditionalClass">
    <div class="card w-100">
      <div class="card-header">
        Logo da capa do carnê
      </div>
      <div class="card-body">
        <input type="file" @change="fileLogoCarneChange" id="file-logo-carne" accept="image/png, image/jpeg" class="d-none">
        <div class="input-group">
          <div class="logo-carnet">
            <img :src="logoUrl">
          </div>
          <button v-on:click="btnUploadHandle()" type="button" class="btn btn-secondary">
            <font-awesome-icon :icon="['fas', 'upload']" fixed-width/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import baseUrl from '@/modules/baseurl'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'

export default {
  name: "ConfigParametroLogoCarne",
  props: {
    aditionalClass: {
      type: String,
      default: "col-12",
    },
  },
  data() {
    return {
      loading: true,
      edit: false,
      value: undefined,
      valueEdit: undefined,
      changedAt: '',
    }
  },
  methods: {
    baseUrl() {
      return baseUrl;
    },

    fileLogoCarneChange(event) {
      let data = new FormData();
      data.append('file', event.target.files[0]);

      loading(true);
      api.post(`/configs/image-upload/logo-carnet`, data).then(() => {
        loading(false);
        const now = new Date();
        this.changedAt = now.getUTCMilliseconds().toString();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    btnUploadHandle(){
      document.getElementById('file-logo-carne').click();
    },
  },
  computed: {
    logoUrl() {
      return `${baseUrl}/print/logo-carnet?${this.changedAt}`;
    },
  },
  created() {
    this.loading = false;
  },
};
</script>

<style scoped>
.logo-carnet {
  border: 1px solid rgb(184, 184, 184);
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  height: 110px;
  text-align: center;

  display: flex;
  justify-content: center;

  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.logo-carnet>img {
  margin: auto;
  max-width: 150px;
  max-height: 100px;
}
</style>