<template>
  <div :class="aditionalClass">
    <div class="card w-100">
      <div class="card-header">
        <span v-html="title"></span>
      </div>
      <div class="card-body">
        <div v-if="loading" class="text-center text-muted">
          <font-awesome-icon :icon="['fas', 'spinner']" spin />
        </div>
        <div v-if="!loading">

          <label v-if="description" :for="dbKey" class="form-label">{{description}}</label>

          <div v-if="!edit" class="input-group">
            <input v-if="kind !== 'textarea'" disabled type="text" v-model="value" class="form-control" placeholder="">
            <textarea v-if="kind === 'textarea'" :rows="rows" disabled v-model="value" class="form-control" placeholder=""></textarea>

            <button v-on:click="btnEditHandle()" type="button" class="btn btn-outline-primary">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
            </button>
          </div>

          <div v-if="edit" class="input-group">

            <!-- type text -->
            <input v-if="kind === 'text'" type="text" v-model="valueEdit" :id="dbKey" class="form-control" placeholder="">

            <!-- type textarea -->
            <textarea v-if="kind === 'textarea'" :rows="rows" v-model="valueEdit" :id="dbKey" class="form-control" placeholder=""></textarea>

            <!-- type decimal -->
            <input v-if="kind === 'decimal'" type="number" step=".01" min="0" @change="setToDecimalDigits" v-model.number="valueEdit" :id="dbKey" class="form-control" placeholder="">
            <input v-if="kind === 'money'"   type="number" step=".01" min="0" @change="setToDecimalDigits" v-model.number="valueEdit" :id="dbKey" class="form-control" placeholder="R$ 0,00">

            <!-- type map-zoom -->
            <input v-if="kind === 'map-zoom'" type="number" min="3" max="19" v-model="valueEdit" placeholder="Zoom (4 ~ 19)" class="form-control">

            <!-- type latitude -->
            <input v-if="kind === 'map-lat'" type="number" min="-90" max="90" v-model="valueEdit" placeholder="Latitude" class="form-control hide-spin">

            <!-- type longitude -->
            <input v-if="kind === 'map-lng'" type="number" min="-180" max="180" v-model="valueEdit" placeholder="Longitude" class="form-control hide-spin">

            <button v-on:click="btnCancelEditHandle()" type="button" class="btn btn-secondary">
              <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
            </button>
            <button v-on:click="btnSaveHandle()" type="button" class="btn btn-success">
              <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'

export default {
  name: "ConfigParametro",
  props: {
    title: {
      type: String,
      default: "-",
    },
    description: {
      type: String,
      default: undefined,
    },
    dbKey:  {
      type: String,
      default: undefined,
    },
    kind: {
      type: String,
      default: "text", //text, number, date, map-lat, map-lng, map-zoom
    },
    aditionalClass: {
      type: String,
      default: "col-12",
    },
    precision: {
      type: Number,
      default: 2,
    },
    rows: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      loading: true,
      edit: false,
      value: undefined,
      valueEdit: undefined,
    }
  },
  methods: {
    refreshValue() {
      this.loading = true;

      api.get(`/configs/parameters`).then(res => {
        this.loading = false;
        this.value = res.data[this.dbKey];
      }).catch(() => {
        // TODO: set error message
      });
    },
    btnEditHandle(){
      this.valueEdit = this.value;
      this.edit = true;

      if (this.kind === 'decimal') {
        this.setToDecimalDigits();
      }
    },
    btnCancelEditHandle(){
      this.edit = false;
    },
    btnSaveHandle(){
      const data = {}
      data[this.dbKey] = this.valueEdit;
      loading(true);

      api.patch(`/configs/parameters`, data).then(() => {
        loading(false);
        this.edit = false;
        this.refreshValue();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
    setToDecimalDigits() {
      if (!this.valueEdit) {
        this.valueEdit = 0.00;
      }

      this.valueEdit = parseFloat(this.valueEdit).toFixed(this.precision);
    }
  },
  created() {
    this.$nextTick(() => {
      this.refreshValue();
    });
  },
};
</script>
