<template>
  <div class="isp-mapa-clientes">
    <h1 class="titulo-card fs-4">Parâmetros do sistema</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
      </div>
      <div class="row g-0">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" aria-current="page" href="#" data-bs-toggle="tab" data-bs-target="#receivables" type="button" role="tab" aria-controls="receivables" aria-selected="true">Recebimentos</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" aria-current="page" href="#" data-bs-toggle="tab" data-bs-target="#online-customers-maps" type="button" role="tab" aria-controls="online-customers-maps" aria-selected="true">Mapa de clientes online</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" aria-current="page" href="#" data-bs-toggle="tab" data-bs-target="#route-monitor-map" type="button" role="tab" aria-controls="route-monitor-map" aria-selected="true">Mapa monitor de rotas</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div class="tab-pane active" id="receivables" role="tabpanel" aria-labelledby="receivables-tab">
            <div class="row g-0">
              <ConfigParametro dbKey="boletoFine" title="Recebimento via boleto: <b>Multa</b>" kind="decimal" aditionalClass="p-1 col-12 col-sm-6 col-md-6" />
              <ConfigParametro dbKey="boletoInterest" title="Recebimento via boleto: <b>Juros</b> (ao dia)" kind="decimal" :precision="3" aditionalClass="p-1 col-12 col-sm-6 col-md-6" />
              <!-- <ConfigParametro dbKey="boletoDiscount" title="Recebimento via boleto: <b>Desconto</b>" kind="money" aditionalClass="p-1 col-12 col-sm-6 col-md-6" /> -->
              <ConfigParametroLogoCarne aditionalClass="p-1 col-12 col-sm-6 col-md-4" />
              <ConfigParametro dbKey="boletoInstructions" title="Recebimento via boleto: <b>Instruções</b>" kind="textarea" :rows="4" aditionalClass="p-1 col-12 col-md-8" />
            </div>
          </div>
          <div class="tab-pane" id="online-customers-maps" role="tabpanel" aria-labelledby="online-customers-maps-tab">
            <div class="row g-0">
              <ConfigParametro title="Coordenadas GPS: Latitude" dbKey="mapLat" kind="map-lat" aditionalClass="p-1 col-12 col-sm-6 col-md-4" />
              <ConfigParametro title="Coordenadas GPS: Longitude" dbKey="mapLng" kind="map-lng" aditionalClass="p-1 col-12 col-sm-6 col-md-4" />
              <ConfigParametro title="Zoom incial" dbKey="mapZoom" kind="map-zoom" aditionalClass="p-1 col-12 col-sm-6 col-md-4" />
            </div>
          </div>
          <div class="tab-pane" id="route-monitor-map" role="tabpanel" aria-labelledby="route-monitor-map-tab">
            <div class="row g-0">
              <ConfigParametro title="Coordenadas GPS: Latitude" dbKey="routeMonitorMapLat" kind="map-lat" aditionalClass="p-1 col-12 col-sm-6 col-md-4" />
              <ConfigParametro title="Coordenadas GPS: Longitude" dbKey="routeMonitorMapLng" kind="map-lng" aditionalClass="p-1 col-12 col-sm-6 col-md-4" />
              <ConfigParametro title="Zoom incial" dbKey="routeMonitorMapZoom" kind="map-zoom" aditionalClass="p-1 col-12 col-sm-6 col-md-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigParametroLogoCarne from '../components/ConfigParametroLogoCarne.vue';
import ConfigParametro from '../components/ConfigParametro.vue';

export default {
  name: 'ConfigParametros',
  components: {
    ConfigParametroLogoCarne,
    ConfigParametro,
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
  },
}
</script>
