function baseUrlF() {
        let hostname = window.location.hostname;

        hostname = hostname.replace('.local.sgmcloud.com.br', '');
        hostname = hostname.replace('.dev.sgmcloud.com.br', '');
        hostname = hostname.replace('.sgmcloud.com.br', '');
        hostname = hostname.replace('.sgmcloud.local', '');

        return `${process.env.VUE_APP_MAIN_API_PREFIX}${hostname}.${process.env.VUE_APP_MAIN_API}/v2`;
}

const baseUrl = baseUrlF();

export default baseUrl
